<template>
  <v-container fluid class="text-center">
    <v-breadcrumbs :items="path" large></v-breadcrumbs>
    <v-toolbar-title dense dark class="title text-left mt-5 mx-5">
      <v-row>
        <v-col cols="12" md="3" xs="12"> Mensajes </v-col>
        <v-col cols="12" md="9" xs="12" class="text-end" style="white-space: normal">
          <span class="caption grey--text darken-2" style="font-family: 'Quicksand', sans-serif !important"
            >Los mensajes que se carguen se mostrarán en esta pantalla, cada vez que un mensaje se envia, se actualiza
            su estado. Cada registro representa un nuevo mensaje NUEVO, PENDIENTE o ENTREGADO.</span
          >
        </v-col>
      </v-row>
    </v-toolbar-title>
    <v-card v-if="campaign" class="elevation-0 mt-5 mx-5">
      <v-row>
        <v-col cols="6">
          <span class="text-subtitle-1 font-weight-bold">{{ campaign.name }}</span
          ><br />
          <span class="text-subtitle-1 font-weight-medium"
            >{{ campaign.category.name }} / {{ campaign.subcategory.name }}</span
          >
        </v-col>
        <v-col cols="6">
          <span class="text-subtitle-1 font-weight-bold">{{ getType(campaign.type) }}</span
          ><br />
          <span v-show="showScheduledDate" class="text-subtitle-1 font-weight-medium text-decoration-underline">{{
            parseDate(campaign.scheduled_date)
          }}</span>
          <v-btn v-if="campaign.active" x-small elevation="0" color="info" class="mx-1" rounded dark>Activada</v-btn>
          <v-btn v-else x-small elevation="0" color="grey" class="mx-1" rounded dark>Desactivada</v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="elevation-5 mt-5 mx-5">
      <v-card-title class="justify-space-between" style="gap: 20px">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          class="mx-2"
          single-line
          hide-details
          maxlength="150"
          style="max-width: 400px"
        ></v-text-field>
        <div>
          <v-btn
            rounded
            small
            :loading="loading"
            elevation="1"
            color="primary"
            class="mx-2 custom-button"
            @click="dialogs.detail = true"
          >
            Detalle
            <v-icon right>mdi-information</v-icon>
          </v-btn>
          <v-btn
            rounded
            small
            :loading="loading"
            elevation="1"
            color="primary"
            class="mx-2 custom-button"
            @click="refresh()"
          >
            Actualizar
            <v-icon right>mdi-refresh</v-icon>
          </v-btn>
          <v-btn
            rounded
            small
            :loading="loading"
            elevation="1"
            color="primary"
            class="mx-2 custom-button"
            @click="dialogs.import = true"
          >
            Importar
            <v-icon right>mdi-arrow-up</v-icon>
          </v-btn>
          <v-btn
            rounded
            small
            :loading="loading"
            elevation="1"
            color="primary"
            class="mx-2 custom-button"
            @click="exportarCSV()"
          >
            Exportar
            <v-icon right>mdi-arrow-down</v-icon>
          </v-btn>
          <v-btn
            rounded
            small
            :loading="loading"
            elevation="1"
            color="primary"
            class="mx-2 custom-button"
            @click="dialogs.sendAll = true"
          >
            Enviar todo
            <v-icon right>mdi-send-circle</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :loading="loading"
        :search="search"
        :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }"
        :items-per-page="10"
        item-key="id"
        class="elevation-1"
        dense
      >
        <v-progress-linear slot="progress" color="primary" indeterminate></v-progress-linear>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left
            ><template v-slot:activator="{ on, attrs }"
              ><v-icon
                class="mr-2"
                size="22"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                :disabled="loading"
                @click="view(item)"
                >mdi-pencil</v-icon
              ></template
            ><span>Editar</span></v-tooltip
          >
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"
              ><v-icon
                class="mr-2"
                size="22"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                :disabled="loading"
                @click="send(item.id_message)"
                >mdi-send</v-icon
              ></template
            ><span>Enviar</span></v-tooltip
          >
          <v-tooltip right
            ><template v-slot:activator="{ on, attrs }"
              ><v-icon
                class="mr-2"
                size="22"
                color="error"
                dark
                v-bind="attrs"
                v-on="on"
                :disabled="loading"
                @click="remove(item.id_message)"
                >mdi-delete</v-icon
              ></template
            ><span>Eliminar</span></v-tooltip
          >
        </template>
        <template v-slot:[`item.status`]="{ item }">
          {{ getStatus(item.status) }}
        </template>
        <template v-slot:[`footer.page-text`]="props">
          Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
        </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogs.import" persistent max-width="800px">
      <v-card>
        <v-toolbar dense dark color="primary" elevation="2">
          <v-toolbar-title>Importar mensajes</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn v-if="!forms.import.data.items.length" text small :loading="loading" @click="acceptImport()">
            Cargar
            <v-icon right>mdi-arrow-up</v-icon>
          </v-btn>
          <v-btn v-else-if="forms.import.data.items.length" text small :loading="loading" @click="saveImport()">
            Guardar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form v-show="!forms.import.data.items.length" ref="formImport" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-file-input
                    v-model="forms.import.id"
                    chips
                    show-size
                    label="Seleccionar archivo..."
                    :accept="catalogues.mime_types"
                    :disabled="loading"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-form>
            <v-data-table
              v-show="forms.import.data.items.length"
              :headers="forms.import.data.headers"
              :items="forms.import.data.items"
              :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [10, 20, -1] }"
              :items-per-page="10"
            >
              <template v-slot:[`footer.page-text`]="props">
                Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
              </template>
              <template v-slot:no-data> No hay información disponible </template>
              <template v-slot:no-results> No se obtuvieron resultados </template>
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogs.detail" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="primary" elevation="2">
          <v-toolbar-title>Detalle de campaña</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row v-if="campaign" class="mt-2">
              <v-col cols="12">
                <v-text-field
                  v-model="campaign.datetime_create"
                  label="Fecha de creacion"
                  dense
                  outlined
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="campaign.datetime_update"
                  label="Fecha de actualización"
                  dense
                  outlined
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="campaign.name" label="Nombre" dense outlined disabled></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="campaign.labels" label="Etiquetas" dense outlined disabled></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="campaign.comments" label="Comentarios" dense outlined disabled></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="campaign.category.name" label="Categoría" dense outlined disabled></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="campaign.subcategory.name"
                  label="Subcategoría"
                  dense
                  outlined
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="campaign.subcategory.name"
                  label="Subcategoría"
                  dense
                  outlined
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="items.length"
                  label="Cantidad mensajes"
                  dense
                  outlined
                  required
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="campaign.total_cost"
                  label="Costo actual"
                  dense
                  outlined
                  required
                  disabled
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12">
                <v-text-field
                  v-model="campaign.provider_setting.name"
                  label="Proveedor"
                  dense
                  outlined
                  required
                  disabled
                ></v-text-field>
              </v-col> -->
              <v-col cols="12">
                <v-text-field
                  v-model="campaign.template"
                  label="Plantilla"
                  dense
                  outlined
                  required
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogs.update" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="primary" elevation="2">
          <v-toolbar-title>Actualizar mensaje</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="update()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUpdate" lazy-validation>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.update.to"
                    label="Destino"
                    :rules="rules.to"
                    :disabled="loading"
                    dense
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="forms.update.body"
                    label="Mensaje"
                    :rules="rules.body"
                    rows="3"
                    :disabled="loading"
                    dense
                    outlined
                    persistent-hint
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.update.date"
                    label="Fecha"
                    readonly
                    dense
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.update.status"
                    label="Estatus"
                    readonly
                    dense
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.update.twilio_sid"
                    label="Twilio SID"
                    readonly
                    dense
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.update.twilio_status"
                    label="Twilio Estatus"
                    readonly
                    dense
                    outlined
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.remove" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea eliminar el mensaje?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" color="blue-grey" @click="cancel()"> Cancelar </v-btn>
          <v-btn text small :loading="loading" color="error" @click="remove()"> Eliminar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.sendAll" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea enviar todos los mensajes?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" color="blue-grey" @click="cancel()"> Cancelar </v-btn>
          <v-btn text small :loading="loading" color="error" @click="sendAll()"> Enviar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.send" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea enviar el mensaje?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" color="blue-grey" @click="cancel()"> Cancelar </v-btn>
          <v-btn text small :loading="loading" color="success" @click="send()"> Enviar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="success" color="success" :timeout="4000" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="4000" bottom right>
      {{ msgError }}
    </v-snackbar>
  </v-container>
</template>

<script>
import services from "@/utils/services";
import moment from "moment-timezone";
import * as XLSX from "xlsx";
import * as Papa from "papaparse";
import { Parser } from "json2csv";

export default {
  name: "message",
  data: () => ({
    loading: false,
    success: false,
    msgSuccess: "",
    error: false,
    msgError: "",
    search: "",
    headers: [],
    items: [],
    path: [
      {
        text: "SMS",
        disabled: false,
        href: "home",
      },
      {
        text: "Campañas",
        disabled: false,
        href: "/campaigns",
      },
      {
        text: "Mensajes",
        disabled: false,
        href: "",
      },
    ],
    selected: [],
    sent: null,
    deleted: null,
    catalogues: {
      types: [],
      status: [],
    },
    forms: {
      update: {
        to: "",
        body: "",
        date: "",
      },
      import: {
        id: null,
        data: {
          headers: [],
          items: [],
        },
      },
    },
    campaign: null,
    listVars: [],
    rules: {
      to: [(v) => !!v || "El campo es requerido", (v) => (v && v.length <= 15) || "El campo excede la longitud máxima"],
      body: [
        (v) => !!v || "El campo es requerido",
        (v) => (v && v.length <= 500) || "El campo excede la longitud máxima",
      ],
    },
    dialogs: {
      import: false,
      update: false,
      send: false,
      remove: false,
      sendAll: false,
      detail: false,
    },
  }),
  mounted() {
    this.getCatalogues();
    this.getDetail();
    this.refresh();
  },
  computed: {
    showScheduledDate() {
      return this.campaign.type === "SCHEDULED_DATE" ? true : false;
    },
  },
  methods: {
    async getCatalogues() {
      const type = await this.axios.get(services.routes.campaign + "/type");
      if (type.data.data) {
        this.catalogues.types = type.data.data;
      }
      const status = await this.axios.get(services.routes.campaign + "/message/status");
      if (status.data.data) {
        this.catalogues.status = status.data.data;
      }
    },
    getDetail() {
      this.loading = true;
      let params = {
        id: this.$route.params.id,
      };
      this.axios
        .get(services.routes.campaign + "/detail", { params })
        .then((response) => {
          this.campaign = response.data.data;
          if (this.campaign.type === "VARIABLE_DATE") {
            this.headers = [
              { text: "ID", align: "left", sortable: false, value: "id_message", sortable: true },
              { text: "Destino", align: "left", sortable: false, value: "to", sortable: true },
              { text: "Mensaje", align: "left", sortable: false, value: "body", sortable: true },
              { text: "Fecha", align: "left", sortable: false, value: "date", sortable: true },
              { text: "Estatus", align: "left", sortable: false, value: "status", sortable: true },
              { text: "Fecha creación", align: "left", sortable: false, value: "datetime_create", sortable: true },
              { text: "Fecha actualización", align: "left", sortable: false, value: "datetime_update", sortable: true },
              { text: "Acciones", align: "left", sortable: false, value: "actions", sortable: true },
            ];
          } else {
            this.headers = [
              { text: "ID", align: "left", sortable: false, value: "id_message", sortable: true },
              { text: "Destino", align: "left", sortable: false, value: "to", sortable: true },
              { text: "Mensaje", align: "left", sortable: false, value: "body", sortable: true },
              { text: "Estatus", align: "left", sortable: false, value: "status", sortable: true },
              { text: "Fecha creación", align: "left", sortable: false, value: "datetime_create", sortable: true },
              { text: "Fecha actualización", align: "left", sortable: false, value: "datetime_update", sortable: true },
              { text: "Acciones", align: "left", sortable: false, value: "actions", sortable: true },
            ];
          }
          console.log(this.campaign, "Campaign");
          this.getListkVars();
        })
        .catch((error) => {
          this.error = true;
          this.msgError = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getListkVars() {
      const match = this.campaign.template.match(/\{v_.[a-zA-ZñÑ0-9]+\}/g);
      if (match) {
        for (let elem of match) {
          const val = elem.replace("{", "").replace("}", "");
          this.listVars.push(val);
        }
      }
    },
    refresh() {
      this.loading = true;
      let params = {
        id: this.$route.params.id,
      };
      this.axios
        .get(services.routes.campaign + "/message", { params })
        .then((response) => {
          this.items = response.data.data;
          for (let i = 0; i < this.items.length; i++) {
            this.items[i].date = this.items[i].date
              ? moment.tz(this.items[i].date, "UTC").local().format("YYYY-MM-DD HH:mm")
              : "";
            this.items[i].datetime_create = moment
              .tz(this.items[i].datetime_create, "UTC")
              .local()
              .format("YYYY-MM-DD HH:mm");
            this.items[i].datetime_update = moment
              .tz(this.items[i].datetime_update, "UTC")
              .local()
              .format("YYYY-MM-DD HH:mm");
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    exportarCSV() {
      const configuracion = {
        fields: [
          { label: "ID", value: "id_message" },
          { label: "Destino", value: "to" },
          { label: "Mensaje", value: "body" },
          { label: "Estatus", value: "status" },
          { label: "Fecha creación", value: "datetime_create" },
          { label: "Fecha actualización", value: "datetime_update" },
        ],
      };

      const parser = new Parser(configuracion);
      const csv = parser.parse(this.items);

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "tabla.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getType(text) {
      for (let elem of this.catalogues.types) {
        if (text === elem.value) {
          return elem.text;
        }
      }
      return "";
    },
    getStatus(text) {
      for (let elem of this.catalogues.status) {
        if (text === elem.value) {
          return elem.text;
        }
      }
      return "";
    },
    parseDate(date) {
      return moment.tz(date, "UTC").local().format("YYYY-MM-DD HH:mm");
    },
    cancel() {
      if (this.dialogs.update) {
        this.$refs.formUpdate.reset();
        for (let key in this.forms.update) {
          this.forms.update[key] = "";
        }
        this.dialogs.update = false;
      } else if (this.dialogs.import) {
        this.forms.import = {
          id: null,
          data: {
            headers: [],
            items: [],
          },
        };
        this.dialogs.import = false;
      } else if (this.dialogs.send) {
        this.dialogs.send = false;
      } else if (this.dialogs.remove) {
        this.dialogs.remove = false;
      } else if (this.dialogs.sendAll) {
        this.dialogs.sendAll = false;
      } else if (this.dialogs.detail) {
        this.dialogs.detail = false;
      }
    },
    view(data) {
      this.forms.update = {
        id_message: data.id_message,
        to: data.to,
        body: data.body,
        date: data.date,
        status: this.getStatus(data.status),
        twilio_sid: data.twilio_sid,
        twilio_status: data.twilio_status,
      };
      this.dialogs.update = true;
    },
    update() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.update));
        this.axios
          .put(services.routes.campaign + "/message", { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.success = true;
            this.msgSuccess = "Mnesaje actualizado exitosamente";
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.error = true;
        this.msgError = "La información es incorrecta";
      }
    },
    send(id) {
      if (!id) {
        this.loading = true;
        let data = {
          campaign: this.campaign.id_campaign,
          message: this.sent,
        };
        this.axios
          .post(services.routes.campaign + "/message/send", { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.success = true;
            this.msgSuccess = "Mensaje enviado exitosamente";
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.sent = id;
        this.dialogs.send = true;
      }
    },
    sendAll() {
      this.loading = true;
      let data = {
        id_campaign: this.campaign.id_campaign,
      };
      this.axios
        .post(services.routes.campaign + "/message/send/all", { data })
        .then((response) => {
          this.cancel();
          this.refresh();
          this.success = true;
          this.msgSuccess = "Mensaje enviado exitosamente";
        })
        .catch((error) => {
          this.error = true;
          this.msgError = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    remove(id) {
      if (!id) {
        this.loading = true;
        this.axios
          .delete(services.routes.campaign + "/message", { data: { data: { id_message: this.deleted } } })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.success = true;
            this.msgSuccess = "Mensaje eliminado exitosamente";
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.deleted = id;
        this.dialogs.remove = true;
      }
    },
    loadValues(data) {
      if (data.length > 1) {
        let headers = [];
        let values = [];
        for (let i = 0; i < data[0].length; i++) {
          if (data[0][i] !== "") {
            headers.push(data[0][i]);
          }
        }
        for (let i = 1; i < data.length; i++) {
          let row = {};
          for (let j = 0; j < data[i].length; j++) {
            row[headers[j]] = data[i][j];
          }
          values.push(row);
        }
        if (this.campaign.type === "VARIABLE_DATE" && headers.length != this.listVars.length + 2) {
          this.error = true;
          this.msgError = "El número de columnas es inválida para el tipo de campaña FECHA VARIABLE";
        } else if (this.campaign.type === "SCHEDULED_DATE" && headers.length != this.listVars.length + 1) {
          this.error = true;
          this.msgError = "El número de columnas es inválida para el tipo de campaña FECHA PROGRAMADA";
        } else if (this.campaign.type === "ON_DEMAND" && headers.length != this.listVars.length + 1) {
          this.error = true;
          this.msgError = "El número de columnas es inválida para el tipo de campaña EJECUCION A DEMANDA";
        } else {
          let rows = [];
          for (let i = 0; i < values.length; i++) {
            if (values[i]["fecha"] && !moment(values[i]["fecha"]).isValid()) {
              rows = [];
              break;
            } else if (values[i]["destino"] === "") {
              rows = [];
              break;
            }
            let to = values[i]["destino"];
            let date = values[i]["fecha"];
            let body = this.campaign.template;
            for (let key in values[i]) {
              if (/^v_.+$/.test(key)) {
                body = body.replace("{" + key + "}", values[i][key]);
              }
            }
            rows.push({
              to: to,
              body: body,
              date: date,
            });
          }
          if (!rows.length) {
            this.error = true;
            this.msgError = "El formato del archivo es inválido";
          } else {
            if (this.campaign.type === "VARIABLE_DATE") {
              this.forms.import.data.headers = [
                { text: "Destino", align: "left", sortable: false, value: "to" },
                { text: "Mensaje", align: "left", sortable: false, value: "body" },
                { text: "Fecha", align: "left", sortable: false, value: "date" },
              ];
            } else {
              this.forms.import.data.headers = [
                { text: "Destino", align: "left", sortable: false, value: "to" },
                { text: "Mensaje", align: "left", sortable: false, value: "body" },
              ];
            }
            this.forms.import.data.items = rows;
          }
        }
      } else {
        this.error = true;
        this.msgError = "El formato del archivo es inválido";
      }
    },
    acceptImport() {
      if (this.forms.import.id) {
        let fr = new FileReader();
        let file = this.forms.import.id;
        let size = this.forms.import.id.size;
        if (size <= 3000000) {
          this.loading = true;
          setTimeout(() => {
            fr.onload = () => {
              let data = XLSX.read(fr.result, { type: "array" });
              let sheetNames = data.SheetNames;
              let worksheet = data.Sheets[sheetNames[0]];
              let csv = XLSX.utils.sheet_to_csv(worksheet).trim();
              let values = Papa.parse(csv);
              if (values.data && values.data.length) {
                values = values.data;
                this.loadValues(values);
              } else {
                this.error = true;
                this.msgError = "El archivo no fue leído correctamente";
              }
              this.loading = false;
            };
            fr.onerror = () => {
              this.loading = false;
              this.forms.import.id = null;
              this.error = true;
              this.msgError = "El archivo no fue leído correctamente";
            };
            fr.readAsArrayBuffer(file);
          }, 500);
        } else {
          this.error = true;
          this.msgError = "El archivo ha excedido el límite permitido (3 MB)";
        }
      } else {
        this.error = true;
        this.msgError = "Seleccionar archivo";
      }
    },
    validarNumeroTelefono(numeroTelefono) {
      const expresionRegular = /^\+?\d{1,3}\d{9,10}$/;
      return expresionRegular.test(numeroTelefono);
    },
    saveImport() {
      this.loading = true;
      let data = {
        campaign: this.campaign.id_campaign,
        messages: this.forms.import.data.items,
      };

      let invalidPhone = [];
      for (let e of data.messages) {
        if (!this.validarNumeroTelefono(e.to)) {
          invalidPhone.push(e.to);
        }
      }

      if (invalidPhone.length > 0) {
        this.error = true;
        this.msgError = `Existen ${invalidPhone.length} télefono(s) invalidos como ${invalidPhone[0]}, favor de validar.`;
        this.loading = false;
        return;
      }

      this.axios
        .post(services.routes.campaign + "/message/import", { data })
        .then((response) => {
          this.cancel();
          this.refresh();
          this.success = true;
          this.msgSuccess = "Mensajes importados exitosamente";
        })
        .catch((error) => {
          this.error = true;
          this.msgError = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.title {
  font-family: "Quicksand", sans-serif !important;
  font-size: 2rem !important;
  line-height: 1.2;
}
</style>
